import breakpoints from './lib/breakpoints';

(function main($) {
  const $window = $(window);
  const $body = $('body');
  const $header = $('#header');
  const $banner = $('#banner');

  // Breakpoints.
  breakpoints({
    xlarge: '(max-width: 1680px)',
    large: '(max-width: 1280px)',
    medium: '(max-width: 980px)',
    small: '(max-width: 736px)',
    xsmall: '(max-width: 480px)'
  });

  // Play initial animations on page load.
  $window.on('load', () => {
    window.setTimeout(() => {
      $body.removeClass('is-preload');
    }, 100);
  });

  // Header.
  if ($banner.length > 0 && $header.hasClass('alt')) {
    $window.on('resize', () => {
      $window.trigger('scroll');
    });

    $banner.scrollex({
      bottom: $header.outerHeight(),
      terminate() {
        $header.removeClass('alt');
      },
      enter() {
        $header.addClass('alt');
      },
      leave() {
        $header.removeClass('alt');
      }
    });
  }

  // Menu.
  const $menu = $('#menu');

  $menu._locked = false;

  $menu._lock = function _lock() {
    if ($menu._locked) {
      return false;
    }

    $menu._locked = true;

    window.setTimeout(() => {
      $menu._locked = false;
    }, 350);

    return true;
  };

  $menu._show = function _show() {
    if ($menu._lock()) {
      $body.addClass('is-menu-visible');
    }
  };

  $menu._hide = function _hide() {
    if ($menu._lock()) {
      $body.removeClass('is-menu-visible');
    }
  };

  $menu._toggle = function _toggle() {
    if ($menu._lock()) {
      $body.toggleClass('is-menu-visible');
    }
  };

  $menu
    .appendTo($body)
    .on('click', event => {
      event.stopPropagation();

      // Hide.
      $menu._hide();
    })
    .find('.inner')
    .on('click', '.close', event => {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      // Hide.
      $menu._hide();
    })
    .on('click', event => {
      event.stopPropagation();
    })
    .on('click', 'a', function onClick(event) {
      const href = $(this).attr('href');

      event.preventDefault();
      event.stopPropagation();

      // Hide.
      $menu._hide();

      // Redirect.
      window.setTimeout(() => {
        window.location.href = href;
      }, 350);
    });

  $body
    .on('click', 'a[href="#menu"]', event => {
      event.stopPropagation();
      event.preventDefault();

      // Toggle.
      $menu._toggle();
    })
    .on('keydown', event => {
      // Hide on escape.
      if (event.keyCode === 27) {
        $menu._hide();
      }
    });
})(window.jQuery);
