(function util($) {
  /**
   * Generate an indented list of links from a nav. Meant for use with panel().
   * @return {jQuery} jQuery object.
   */
  $.fn.navList = function navList() {
    const $this = $(this);
    const $anchors = $this.find('a');
    const b = [];

    $anchors.each(function eachAnchor() {
      const a = $(this);

      const indent = Math.max(0, a.parents('li').length - 1);

      const href = a.attr('href');

      const target = a.attr('target');

      b.push(
        `${'<a class="link depth-'}${indent}"${
          typeof target !== 'undefined' && target !== '' ? ` target="${target}"` : ''
        }${typeof href !== 'undefined' && href !== '' ? ` href="${href}"` : ''}>`
          + `<span class="indent-${indent}"></span>${a.text()}</a>`,
      );
    });

    return b.join('');
  };

  /**
   * Panel-ify an element.
   * @param {object} userConfig User config.
   * @return {jQuery} jQuery object.
   */
  $.fn.panel = function panel(userConfig) {
    const $this = $(this);
    // No elements?
    if (this.length === 0) {
      return $this;
    }

    // Multiple elements?
    if (this.length > 1) {
      for (let i = 0; i < this.length; i += 1) {
        $(this[i]).panel(userConfig);
      }

      return $this;
    }

    // Vars.
    const $body = $('body');

    const $window = $(window);

    const id = $this.attr('id');

    // Config.
    const config = $.extend(
      {
        // Delay.
        delay: 0,

        // Hide panel on link click.
        hideOnClick: false,

        // Hide panel on escape keypress.
        hideOnEscape: false,

        // Hide panel on swipe.
        hideOnSwipe: false,

        // Reset scroll position on hide.
        resetScroll: false,

        // Reset forms on hide.
        resetForms: false,

        // Side of viewport the panel will appear.
        side: null,

        // Target element for "class".
        target: $this,

        // Class to toggle.
        visibleClass: 'visible',
      },
      userConfig,
    );

    // Expand "target" if it's not a jQuery object already.
    // eslint-disable-next-line
    if (typeof config.target !== 'jQuery') {
      config.target = $(config.target);
    }

    // Panel.

    // Methods.
    // eslint-disable-next-line
    $this._hide = function hide(event) {
      // Already hidden? Bail.
      if (!config.target.hasClass(config.visibleClass)) {
        return;
      }

      // If an event was provided, cancel it.
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      // Hide.
      config.target.removeClass(config.visibleClass);

      // Post-hide stuff.
      window.setTimeout(() => {
        // Reset scroll position.
        if (config.resetScroll) {
          $this.scrollTop(0);
        }

        // Reset forms.
        if (config.resetForms) {
          $this.find('form').each(function eachForm() {
            this.reset();
          });
        }
      }, config.delay);
    };

    // Vendor fixes.
    $this
      .css('-ms-overflow-style', '-ms-autohiding-scrollbar')
      .css('-webkit-overflow-scrolling', 'touch');

    // Hide on click.
    if (config.hideOnClick) {
      $this.find('a').css('-webkit-tap-highlight-color', 'rgba(0,0,0,0)');

      $this.on('click', 'a', function onClick(event) {
        const $a = $(this);

        const href = $a.attr('href');

        const target = $a.attr('target');

        if (!href || href === '#' || href === '' || href === `#${id}`) {
          return;
        }

        // Cancel original event.
        event.preventDefault();
        event.stopPropagation();

        // Hide panel.
        // eslint-disable-next-line
        $this._hide();

        // Redirect to href.
        window.setTimeout(() => {
          if (target === '_blank') {
            window.open(href);
          } else {
            window.location.href = href;
          }
        }, config.delay + 10);
      });
    }

    // Event: Touch stuff.
    $this.on('touchstart', (event) => {
      $this.touchPosX = event.originalEvent.touches[0].pageX;
      $this.touchPosY = event.originalEvent.touches[0].pageY;
    });

    $this.on('touchmove', (event) => {
      if ($this.touchPosX === null || $this.touchPosY === null) {
        return;
      }

      const diffX = $this.touchPosX - event.originalEvent.touches[0].pageX;

      const diffY = $this.touchPosY - event.originalEvent.touches[0].pageY;

      const th = $this.outerHeight();

      const ts = $this.get(0).scrollHeight - $this.scrollTop();

      // Hide on swipe?
      if (config.hideOnSwipe) {
        let result = false;

        const boundary = 20;

        const delta = 50;

        switch (config.side) {
          case 'left':
            result = diffY < boundary && diffY > -1 * boundary && diffX > delta;
            break;

          case 'right':
            result = diffY < boundary && diffY > -1 * boundary && diffX < -1 * delta;
            break;

          case 'top':
            result = diffX < boundary && diffX > -1 * boundary && diffY > delta;
            break;

          case 'bottom':
            result = diffX < boundary && diffX > -1 * boundary && diffY < -1 * delta;
            break;

          default:
            break;
        }

        if (result) {
          $this.touchPosX = null;
          $this.touchPosY = null;
          // eslint-disable-next-line
          $this._hide();

          return;
        }
      }

      // Prevent vertical scrolling past the top or bottom.
      if (($this.scrollTop() < 0 && diffY < 0) || (ts > th - 2 && ts < th + 2 && diffY > 0)) {
        event.preventDefault();
        event.stopPropagation();
      }
    });

    // Event: Prevent certain events inside the panel from bubbling.
    $this.on('click touchend touchstart touchmove', (event) => {
      event.stopPropagation();
    });

    // Event: Hide panel if a child anchor tag pointing to its ID is clicked.
    $this.on('click', `a[href="#${id}"]`, (event) => {
      event.preventDefault();
      event.stopPropagation();

      config.target.removeClass(config.visibleClass);
    });

    // Body.

    // Event: Hide panel on body click/tap.
    $body.on('click touchend', (event) => {
      // eslint-disable-next-line
      $this._hide(event);
    });

    // Event: Toggle.
    $body.on('click', `a[href="#${id}"]`, (event) => {
      event.preventDefault();
      event.stopPropagation();

      config.target.toggleClass(config.visibleClass);
    });

    // Window.

    // Event: Hide on ESC.
    if (config.hideOnEscape) {
      $window.on('keydown', (event) => {
        if (event.keyCode === 27) {
          // eslint-disable-next-line
          $this._hide(event);
        }
      });
    }

    return $this;
  };

  /**
   * Apply "placeholder" attribute polyfill to one or more forms.
   * @return {jQuery} jQuery object.
   */
  $.fn.placeholder = function placeholder() {
    const $this = $(this);

    // Browser natively supports placeholders? Bail.
    if (typeof document.createElement('input').placeholder !== 'undefined') {
      return $this;
    }

    // No elements?
    if (this.length === 0) {
      return $this;
    }

    // Multiple elements?
    if (this.length > 1) {
      for (let i = 0; i < this.length; i += 1) {
        $(this[i]).placeholder();
      }

      return $this;
    }

    // Vars.

    // Text, TextArea.
    $this
      .find('input[type=text],textarea')
      .each(function eachText() {
        const i = $(this);

        if (i.val() === '' || i.val() === i.attr('placeholder')) {
          i.addClass('polyfill-placeholder').val(i.attr('placeholder'));
        }
      })
      .on('blur', function textBlur() {
        const i = $(this);

        if (i.attr('name').match(/-polyfill-field$/)) {
          return;
        }

        if (i.val() === '') {
          i.addClass('polyfill-placeholder').val(i.attr('placeholder'));
        }
      })
      .on('focus', function textFocus() {
        const i = $(this);

        if (i.attr('name').match(/-polyfill-field$/)) {
          return;
        }

        if (i.val() === i.attr('placeholder')) {
          i.removeClass('polyfill-placeholder').val('');
        }
      });

    // Password.
    $this.find('input[type=password]').each(function eachPassword() {
      const i = $(this);
      const x = $(
        $('<div>')
          .append(i.clone())
          .remove()
          .html()
          .replace(/type="password"/i, 'type="text"')
          .replace(/type=password/i, 'type=text'),
      );

      if (i.attr('id') !== '') {
        x.attr('id', `${i.attr('id')}-polyfill-field`);
      }

      if (i.attr('name') !== '') {
        x.attr('name', `${i.attr('name')}-polyfill-field`);
      }

      x.addClass('polyfill-placeholder')
        .val(x.attr('placeholder'))
        .insertAfter(i);

      if (i.val() === '') {
        i.hide();
      } else {
        x.hide();
      }

      i.on('blur', (event) => {
        event.preventDefault();

        const y = i.parent().find(`input[name=${i.attr('name')}-polyfill-field]`);

        if (i.val() === '') {
          i.hide();
          y.show();
        }
      });

      x.on('focus', (event) => {
        event.preventDefault();

        const index = x
          .parent()
          .find(`input[name=${x.attr('name').replace('-polyfill-field', '')}]`);

        x.hide();

        index.show().focus();
      }).on('keypress', (event) => {
        event.preventDefault();
        x.val('');
      });
    });

    // Events.
    $this
      .on('submit', () => {
        $this.find('input[type=text],input[type=password],textarea').each(function submit() {
          const i = $(this);

          if (i.attr('name').match(/-polyfill-field$/)) {
            i.attr('name', '');
          }

          if (i.val() === i.attr('placeholder')) {
            i.removeClass('polyfill-placeholder');
            i.val('');
          }
        });
      })
      .on('reset', (event) => {
        event.preventDefault();

        $this.find('select').val($('option:first').val());

        $this.find('input,textarea').each(function eachInput() {
          const i = $(this);

          let x;

          i.removeClass('polyfill-placeholder');

          switch (this.type) {
            case 'submit':
            case 'reset':
              break;

            case 'password':
              i.val(i.attr('defaultValue'));

              x = i.parent().find(`input[name=${i.attr('name')}-polyfill-field]`);

              if (i.val() === '') {
                i.hide();
                x.show();
              } else {
                i.show();
                x.hide();
              }

              break;

            case 'checkbox':
            case 'radio':
              i.attr('checked', i.attr('defaultValue'));
              break;

            case 'text':
            case 'textarea':
              i.val(i.attr('defaultValue'));

              if (i.val() === '') {
                i.addClass('polyfill-placeholder');
                i.val(i.attr('placeholder'));
              }

              break;

            default:
              i.val(i.attr('defaultValue'));
              break;
          }
        });
      });

    return $this;
  };

  /**
   * Moves elements to/from the first positions of their respective parents.
   * @param {jQuery} $elements Elements (or selector) to move.
   * @param {bool} condition If true, moves elements to the top.
   * Otherwise, moves elements back to their original locations.
   */
  $.prioritize = function prioritize($elements, condition) {
    const key = '__prioritize';

    // Expand $elements if it's not already a jQuery object.
    // eslint-disable-next-line
    const elements = typeof $elements !== 'jQuery' ? $($elements) : $elements;

    // Step through elements.
    elements.each(function eachElement() {
      const $e = $(this);
      let $p;

      const $parent = $e.parent();

      // No parent? Bail.
      if ($parent.length === 0) {
        return;
      }

      // Not moved? Move it.
      if (!$e.data(key)) {
        // Condition is false? Bail.
        if (!condition) {
          return;
        }

        // Get placeholder (which will serve as our point of reference for
        // when this element needs to move back).
        $p = $e.prev();

        // Couldn't find anything? Means this element's already at the top, so bail.
        if ($p.length === 0) {
          return;
        }

        // Move element to top of parent.
        $e.prependTo($parent);

        // Mark element as moved.
        $e.data(key, $p);
      } else {
        // Condition is true? Bail.
        if (condition) {
          return;
        }

        $p = $e.data(key);

        // Move element back to its original location (using our placeholder).
        $e.insertAfter($p);

        // Unmark element as moved.
        $e.removeData(key);
      }
    });
  };
}(window.jQuery));
